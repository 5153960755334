import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { ElBotton } from "../../../../components/common/ElBotton";
import { useFirebaseContext } from "../../../../Services/Firebase";
import { useAuthUser } from "../../../../Services/useAuthUser";
import { useExamenContext } from "../../ExamenContext";
import wellcomeHeader from "./wellcome-header.png";

const Wrapper = styled.div``;

const Header = styled.div`
  background-color: #ebced0;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    height: 207px;
    width: auto;
    margin: 0 auto;
  }

  > h2 {
    width: 100%;
    color: white;
    background-color: #000;
    text-align: center;
    padding: 8px;
    margin: 0;
  }
`;

const Contenido = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 42px auto;
  width: 570px;

  > p {
    font-size: 14px;
    text-align: center;
  }
`;

const FeedbackArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 72px;

  > textarea {
    background-color: #f7f7f7;
    width: 100%;
    resize: none;
    height: 92px;
    margin-bottom: 12px;
    font-size: 14px;
  }
`;

export const UserWellcomeView: FC<{
  comenzarExamen: () => void;
  irResultados: () => void;
}> = ({ comenzarExamen, irResultados }) => {
  const { curso, idCourse, userRespuestas } = useExamenContext();
  const firebase = useFirebaseContext();
  const { authUser } = useAuthUser();

  useEffect(() => {
    if (userRespuestas) {
      if (userRespuestas.completado) {
        irResultados();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [comentario, setComentario] = useState(userRespuestas?.comentario || "");

  async function guardarComentario() {
    const snapshoot = firebase.cursoExamenRespuestas(idCourse).doc(authUser.email);

    await snapshoot.update({ comentario });

    alert("Muchas gracias, tu comentario ha sido guardado");

    setPuedeContinuar(true);
  }

  const [puedeContinuar, setPuedeContinuar] = useState(!!userRespuestas?.comentario);

  return (
    <Wrapper>
      <Header>
        <img src={wellcomeHeader} alt="" />
        <h2>
          Examen <br /> {curso?.nombreCurso}
        </h2>
      </Header>

      <Contenido>
        <p>
          Este examen sirve para certificar los conocimientos adquiridos durante el diplomado, no hay tiempo límite y puedes apoyarte de los manuales
          o apuntes personales.
        </p>

        <p>
          Aprobar el examen sera requisito para obtener tu certificación, la cual es indispensable para tener descuentos en nuestros cursos futuros.
        </p>

        <p style={{ marginBottom: "42px" }}>El examen será una manera de comprobar tus conocimientos, disfruta el proceso y ¡diviértete!</p>

        <p style={{ marginBottom: "42px" }}>Pero antes...</p>

        <p>
          Nos gustaría recibir una retroalimentación acerca del diplomado... Queremos brindarte lo mejor de nosotros y estamos dispuestos a seguir
          mejorando. Comentanos que te pareció el diplomado.
        </p>

        <FeedbackArea>
          <textarea value={comentario} onChange={(e) => setComentario(e.target.value)}></textarea>
          <ElBotton disabled={comentario.length < 5} onClick={guardarComentario} color="gris">
            Enviar
          </ElBotton>
        </FeedbackArea>

        {userRespuestas?.completado && (
          <ElBotton color="verde" onClick={irResultados}>
            Ver resultados
          </ElBotton>
        )}

        {!userRespuestas?.completado && (
          <ElBotton disabled={!puedeContinuar} color="verde" onClick={comenzarExamen}>
            Comenzar examen
          </ElBotton>
        )}
      </Contenido>
    </Wrapper>
  );
};
