import React, { useRef, FC, useEffect } from "react";
import styled from "styled-components";
import { ElBotton } from "../../../components/common/ElBotton";

import diplomabase from './diploma-sanando-amor.jpeg';

const Wrapper = styled.div`
  padding: 8px;
  margin-bottom: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

type DiplomaSanandoAmorProps = {
  nombre: string;
  generacion: string;
};

export const DiplomaSanandoAmor: FC<DiplomaSanandoAmorProps> = ({
  nombre,
  generacion,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    function generar() {
      if (!canvasRef.current) return;

      const context = canvasRef.current.getContext("2d");
      const diploma = new Image();
      diploma.crossOrigin = "anonymous"

      diploma.onload = function () {
        if (!context) return;
        context.drawImage(diploma, 0, 0);
        context.font = "52pt serif";
        context.fillText(nombre, 220, 814);

        context.font = "32pt sans-serif";

        // generate today date on a dd/mm/yyyy format
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, "0");
        const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        const yyyy = today.getFullYear();
        const dateStr = `${dd}/${mm}/${yyyy}`;

        context.fillText(dateStr, 490, 1370);
      };

      diploma.src = diplomabase;
    }

    generar();
  }, [generacion, nombre]);

  function download() {
    var link = document.createElement("a");
    link.download = "diploma.png";
    link.href = canvasRef?.current?.toDataURL() as string;
    link.click();
  }

  return (
    <Wrapper>
      <h4>¡Felicidades! Eres un alumno destacado!</h4>
      <canvas
        width={1200}
        height={1600}
        ref={canvasRef}
        style={{ width: "auto", height: "512px", marginBottom: '16px' }}
      ></canvas>
      <ElBotton onClick={download}>descargar</ElBotton>
    </Wrapper>
  );
};

