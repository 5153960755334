import React, { useRef, FC, useEffect } from "react";
import { ElBotton } from "../../../components/common/ElBotton";

import diplomabase from "./diploma-fengshui-2024.jpeg";

type DiplomaFengshuiProps = {
  nombre: string;
  generacion: string;
};

export const DiplomaFengshui: FC<DiplomaFengshuiProps> = ({
  nombre,
  generacion,
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    function generar() {
      if (!canvasRef.current) return;

      const context = canvasRef.current.getContext("2d");
      const diploma = new Image();
      diploma.crossOrigin = "anonymous";

      diploma.onload = function () {
        if (!context) return;
        context.drawImage(diploma, 0, 0);
        context.font = "52pt serif";
        context.fillStyle = "black";
        context.fillText(nombre, 620, 627);

        context.font = "14pt sans-serif";
        context.fillText("GENERACION " + generacion, 920, 40);
      };

      diploma.src = diplomabase;
    }

    generar();
  }, [generacion, nombre]);

  function download() {
    var link = document.createElement("a");
    link.download = "diploma.png";
    link.href = canvasRef?.current?.toDataURL() as string;
    link.click();
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: '32px'
      }}
    >
      <canvas
      width={1600}
      height={1500}
      ref={canvasRef}
      style={{ width: "688px", height: "auto" }}
      ></canvas>
      <ElBotton onClick={download}>descargar</ElBotton>
    </div>
  );
};
