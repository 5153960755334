import React from "react";
import styled from 'styled-components'
import { useAuthUser } from "../../Services/useAuthUser";
import { UserHome } from "./Pages/Users/PageUsers";
import { ExamenContextProvider } from "./ExamenContext";
import { AdminHome } from "./Pages/Admin/PageAdmin";


const Wrapper = styled.div`
  font-family: 'Montserrat' !important;
  height: 100%;
`

//TODO: Tu hiciste esto, haslo bien con el routing correcto
// Esta es una buena tarea para el Melo
// no quiero - melo 
export const CoursoExamPage = () => {
  const { isAdmin } = useAuthUser();


  return (
    <ExamenContextProvider>
      <Wrapper>
        {/* --- Admin View --- */}
        {isAdmin && (
            <AdminHome />
        )}

        {!isAdmin && <UserHome />}
        {/* --- User View --- */}
        {/* welcome view */}
        {/* Question show */}
        {/* Result screen */}
      </Wrapper>
    </ExamenContextProvider>
  );
};
